import React, { useState, useRef, useEffect } from 'react';
import { Modal, Input, Tag, Button, Alert } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { fetchThreadsData } from "../../endpoints/fetchThreadsData";
import CheckmarkAnimation from '../CheckmarkAnimation';

const EmailEditor = ({ visible, onClose, userData, orderId, poNum = null, summary = null, to = null, handleSendEmailOverride = null, externalLoading=null, externalSuccess=null}) => {
    const [recipients, setRecipients] = useState([]);
    const [recipientInput, setRecipientInput] = useState('');
    const [messageBody, setMessageBody] = useState('');
    const [subject, setSubject] = useState(poNum ? 'Order Summary - PO#' + poNum : 'Order Summary');
    const [loading, setLoading] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [showFailed, setShowFailed] = useState(null);

    const inputRef = useRef(null);

    useEffect(() => {
        if (!visible) {
            setRecipients([]);
            setRecipientInput('');
            setMessageBody('');
            setSubject('');
            setShowSuccess(false);
            setShowFailed(null);
        } else {
            if (to && to.length > 0) setRecipients([...to].filter((t) => t));
            setSubject(poNum ? 'Order Summary - PO# ' + poNum : 'Order Summary');
            setMessageBody(summary);
        }
    }, [visible, poNum, summary]);

    const handleSendEmail = async (messageBody, recipients, subject, orderId) => {
        setLoading(true);
        try {
            const result = await fetchThreadsData(
                userData.accessToken,
                {
                    'info_type': 'send_email', 
                    'message_body': messageBody, 'to_emails': recipients, 'subject': subject, 'order_id': orderId,
                    'cc': [userData.email]
                }
            );
            
            if (result['success']) {
                setShowSuccess(true);
                setLoading(false);
                setTimeout(() => {
                    onClose(result['props']);      
                }, 1000);  
            }
            else {
                setShowFailed(result['message']);
                setLoading(false);
            }

        } catch (error) {
            console.error('Error sending email:', error);
        }
    };

    const handleRecipientInputChange = (e) => {
        setRecipientInput(e.target.value);
    };

    const handleInputConfirm = () => {
        const trimmedInput = recipientInput.trim();
        if (trimmedInput && !recipients.includes(trimmedInput)) {
            setRecipients([...recipients, trimmedInput]);
            setRecipientInput('');
        }
    };

    const handleRemoveRecipient = (removedRecipient) => {
        const newRecipients = recipients.filter(recipient => recipient !== removedRecipient);
        setRecipients(newRecipients);
        inputRef.current?.focus(); // Return focus to input only when a tag is closed
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleInputConfirm();
            e.preventDefault();
        }
    };

    // Update: Removed automatic refocusing from handleBlur
    const handleBlur = () => {
        handleInputConfirm();
    };

    return (
        <Modal
            title="Email Order Summary"
            open={visible}
            onCancel={onClose}
            width={800}
            footer={[
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <Button key="cancel" onClick={onClose}>Cancel</Button>
                    <Button key="send" type="primary" onClick={handleSendEmailOverride !== null ? () => handleSendEmailOverride(messageBody, recipients, subject, orderId) : () => handleSendEmail(messageBody, recipients, subject, orderId)} disabled={externalLoading || loading || recipients.length === 0}>
                        {(loading || externalLoading)  ? <LoadingOutlined /> : 'Send Email'}
                    </Button>
                </div>
            ]}
        >
            <div style={{ marginBottom: '5px', display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
                {recipients.map((recipient, index) => (
                    <Tag
                        key={recipient}
                        closable
                        onClose={() => handleRemoveRecipient(recipient)}
                        style={{ marginBottom: '5px' }}
                    >
                        {recipient}
                    </Tag>
                ))}
                <Input
                    addonBefore='to:'
                    ref={inputRef}
                    type="text"
                    size="small"
                    style={{ flex: '1', marginBottom: '5px' }}
                    value={recipientInput}
                    onChange={handleRecipientInputChange}
                    onKeyDown={handleKeyDown}
                    onBlur={handleBlur}
                    placeholder="Enter email address..."
                />
            </div>
            {userData && userData.email && userData.email.trim() !== '' && (
                <div style={{ marginBottom: '5px', display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
                    <Tag
                        key="cc-email"
                        style={{ marginBottom: '5px' }}
                    >
                        cc: {userData.email}
                    </Tag>
                </div>
            )}
            <Input
                addonBefore={'Subject'}
                value={subject}
                onChange={e => setSubject(e.target.value)}
                style={{ marginBottom: '25px' }}
            />
            {(showSuccess || externalSuccess) ? <CheckmarkAnimation/> : <Input.TextArea
                rows={messageBody ? messageBody.split(/\n/).length ?? 10: 10}
                value={messageBody}
                onChange={e => setMessageBody(e.target.value)}
                placeholder="Type your message here..."
            />}
            {showSuccess && <Alert
                message={'Email Sent'}
                type="success"
                showIcon
                style={{
                    position: 'absolute',
                    bottom: 10,
                    left: '50%',
                    transform: 'translateX(-50%)',
                    zIndex: 2000,
                    width: '25%'
                }}
            />}
        {showFailed && <Alert
            message={showFailed}
            type="error"
            showIcon
            closable
            style={{
                position: 'absolute',
                bottom: 10,
                left: '50%',
                transform: 'translateX(-50%)',
                zIndex: 1000,
                width: '35%'
            }}
        />}
        </Modal>
    );
};

export { EmailEditor };