import * as XLSX from 'xlsx';
import dayjs from "dayjs";

const volumeFormatter = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

const detailedVolumeFormatter = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 2, // (causes 2500.99 to be printed as $2,501)
});

const detailedCurrencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits: 2, // (causes 2500.99 to be printed as $2,501)
});


const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

const parseTimeKey = (str_in, aggPeriod) => {
    let date;
    if (aggPeriod === 'MS') {
        // Parse string in the format 'MMM YYYY', e.g. 'Jan 2021'
        date = new Date(Date.parse(str_in));
    } else if (aggPeriod === 'W') {
        // Parse string in the format 'MMM DD YYYY', e.g. 'Jan 01 2021'
        date = new Date(Date.parse(str_in));
    } else if (aggPeriod === 'QS') {
        const parts = str_in.split(' ');
        const q = parts[0];
        const y = parseInt(parts[1]);

        let month;
        if (q === 'Q1') {
            month = 0; // Jan
        } else if (q === 'Q2') {
            month = 3; // Apr
        } else if (q === 'Q3') {
            month = 6; // Jul
        } else if (q === 'Q4') {
            month = 9; // Oct
        }

        // Return a Date object for the first day of the determined month and year
        date = new Date(y, month, 1);
    } else {
        throw new Error('Not implemented');
    }

    return date;
}


const buildTimeKey = (m, aggPeriod) => {
    // this builds an index for a time period's data returned by backed
    let mstr;
    if (aggPeriod === 'MS') {
        const parts = m.toDateString().split(' ');
        mstr = parts[1] + ' ' + parts[3];
    } else if (aggPeriod === 'W') {
        const parts = m.toDateString().split(' ');
        mstr = parts[1] + ' ' + parts[2] + ' ' + parts[3];
    } else if (aggPeriod === 'QS') {
        let q;
        if (m.getMonth() <= 2) { // January, February, March
            q = 1;
        } else if (m.getMonth() <= 5) { // April, May, June
            q = 2;
        } else if (m.getMonth() <= 8) { // July, August, September
            q = 3;
        } else { // October, November, December
            q = 4;
        }
        mstr = 'Q' + q + ' ' + m.getFullYear();
    } else if (aggPeriod === 'AS') {
        mstr = m.getFullYear();
    }

    return mstr;
}

const getNextPeriod = (current, aggPeriod) => {
    const nextPeriod = new Date(current);

    if (aggPeriod === 'W') {
        nextPeriod.setDate(nextPeriod.getDate() + 7);
    } else if (aggPeriod === 'MS') {
        nextPeriod.setMonth(nextPeriod.getMonth() + 1);
    } else if (aggPeriod === 'QS') {
        nextPeriod.setMonth(nextPeriod.getMonth() + 3);
    }

    return nextPeriod;
}

const buildTimePeriods = (aggPeriod) => {
    const now = new Date();
    const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
    const startOfWeek = new Date(now.getFullYear(), now.getMonth(), now.getDate() - now.getDay());

    const thisPeriod = aggPeriod === 'MS' ? startOfMonth : startOfWeek;

    let futureStart = new Date(thisPeriod);
    let pastStart = new Date(thisPeriod);

    if (aggPeriod === 'MS') {
        pastStart.setMonth(pastStart.getMonth() - 18); // Start from 18 months ago
    } else if (aggPeriod === 'W') {
        pastStart.setDate(pastStart.getDate() - 18 * 7); // Start from 18 weeks ago
    } else if (aggPeriod === 'QS') {
        pastStart.setMonth(pastStart.getMonth() - 6 * 3); // Start from 6 quarters ago
    }

    let timePeriods = [];
    let pastTimePeriods = [];

    let futureLoop = new Date(futureStart);
    let pastLoop = new Date(pastStart);

    if (aggPeriod === 'W') {
        for (let i = 0; i < 18; i++) {
            timePeriods.push(new Date(futureLoop));
            futureLoop.setDate(futureLoop.getDate() + 7);
        }

        for (let i = 0; i < 18; i++) {
            pastTimePeriods.push(new Date(pastLoop));
            pastLoop.setDate(pastLoop.getDate() + 7);
        }
    } else if (aggPeriod === 'MS') {
        for (let i = 0; i < 18; i++) {
            timePeriods.push(new Date(futureLoop));
            futureLoop.setMonth(futureLoop.getMonth() + 1);
        }

        for (let i = 0; i < 18; i++) {
            pastTimePeriods.push(new Date(pastLoop));
            pastLoop.setMonth(pastLoop.getMonth() + 1);
        }
    } else if (aggPeriod === 'QS') {
        for (let i = 0; i < 6; i++) {
            timePeriods.push(new Date(futureLoop));
            futureLoop.setMonth(futureLoop.getMonth() + 3);
        }

        for (let i = 0; i < 6; i++) {
            pastTimePeriods.push(new Date(pastLoop));
            pastLoop.setMonth(pastLoop.getMonth() + 3);
        }
    }

    return {timePeriods, pastTimePeriods}
}

const buildWeekLabel = (date) => {
    // builds e.g. Sep 10th - Sep 27th


    // Clone the original date
    let endDate = new Date(date.getTime());

    // Add 7 days to the cloned date
    endDate.setDate(date.getDate() + 6);

    // Function to generate ordinal suffix for date
    const getOrdinalSuffix = (date) => {
        let j = date % 10,
            k = date % 100;
        if (j === 1 && k !== 11) {
            return date + "st";
        }
        if (j === 2 && k !== 12) {
            return date + "nd";
        }
        if (j === 3 && k !== 13) {
            return date + "rd";
        }
        return date + "th";
    }

    // Get month name
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    // Build the date string
    const startStr = `${monthNames[date.getMonth()]} ${getOrdinalSuffix(date.getDate())}`;
    const endStr = `${monthNames[endDate.getMonth()]} ${getOrdinalSuffix(endDate.getDate())}`;

    return `${startStr} - ${endStr}`;
}

const getPeriodStart = (date, freq) => {
    const today = date;
    today.setHours(0, 0, 0, 0);  // Reset time to midnight

    if (freq === 'MS') {
        today.setDate(1);
        return today;
    } else if (freq === 'W') {
        // Move the date back to the most recent Sunday
        today.setDate(today.getDate() - today.getDay());
        return today;
    } else if (freq === 'QS') {
        // Determine the current quarter
        const month = today.getMonth() + 1;  // JS months are 0-indexed

        if (month <= 3) {
            return new Date(today.getFullYear(), 0, 1);  // January 1st
        } else if (month <= 6) {
            return new Date(today.getFullYear(), 3, 1);  // April 1st
        } else if (month <= 9) {
            return new Date(today.getFullYear(), 6, 1);  // July 1st
        } else {
            return new Date(today.getFullYear(), 9, 1);  // October 1st
        }
    } else {
        throw new Error("NotImplementedError");
    }
}

function findChangedIndex(original, modified) {
    if (original.length < modified.length) {
        // Item added in modified list
        for (let i = 0; i < modified.length; i++) {
            if (i >= original.length || original[i] !== modified[i]) {
                return i;
            }
        }
    } else if (original.length > modified.length) {
        // Item removed from original list
        for (let i = 0; i < original.length; i++) {
            if (i >= modified.length || original[i] !== modified[i]) {
                return i;
            }
        }
    } else {
        // No item was added or removed
        return -1;
    }
}

const exportToCSV = (data, filename) => {
    if (!data || !data.length) {
        return;
    }

    const headers = Object.keys(data[0]);
    const csvRows = [];

    // Add the header row
    csvRows.push(headers.join(','));

    // Add data rows
    data.forEach(row => {
        const values = headers.map(header => {
            let val = row[header];
            if (val === null || val === undefined) {
                val = '';
            }
            val = val.toString().replace(/"/g, '""'); // Escape double quotes
            if (val.search(/("|,|\n)/g) >= 0) {
                val = `"${val}"`;
            }
            return val;
        });
        csvRows.push(values.join(','));
    });

    const csvContent = csvRows.join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

    // For IE 10+
    if (navigator.msSaveBlob) {
        navigator.msSaveBlob(blob, filename + '.csv');
    } else {
        const link = document.createElement("a");
        if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", filename + '.csv');
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
}

const exportToExcel = (dataArray, fileName) => {
    // Create a new workbook
    const wb = XLSX.utils.book_new();
  
    // Convert the array of dictionaries to a worksheet
    const ws = XLSX.utils.json_to_sheet(dataArray);
  
    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  
    // Write the workbook and initiate download
    XLSX.writeFile(wb, `${fileName}.xlsx`);
  };

  function nullSafeConcat(arr1, arr2) {
    if (arr2 === null) {
        return arr1;
    }
    return arr1.concat(arr2);
}

function convertDayjsToStrings(obj) {
    // used to conserve timezone info in call to JSON.stringify 
    const isObject = val => val && typeof val === 'object';
    if (isObject(obj)) {
        for (const key in obj) {
            if (obj[key] instanceof dayjs) {
                obj[key] = obj[key].format();
            } else if (isObject(obj[key])) {
                convertDayjsToStrings(obj[key]);
            }
        }
    }
    return obj;
}


function convertStringsToDayjs(obj) {
    // used to convert stringified dates back to dayjs objects 
    const isObject = val => val && typeof val === 'object';
    const isDayjsString = str => str && typeof str === 'string' && /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str);
    
    if (isObject(obj)) {
        for (const key in obj) {
            if (isDayjsString(obj[key])) {
                obj[key] = dayjs(obj[key]);
            } else if (isObject(obj[key])) {
                convertStringsToDayjs(obj[key]);
            }
        }
    }
    return obj;
}

const generateOrderSummary = (formState, dataSource, unitDisplayLabels) => {
    // let summary = `Order Summary:\r\n\r\n`;

    // // Append Customer Name
    // summary += `Customer: ${formState.CUSTOMER_MATCHES?.name || 'N/A'}\r\n`;

    // // Append Ship To Details
    // summary += `Ship To: ${formState.SHIP_TO_MATCHES?.name || 'N/A'}\r\n\n`;

    // // Append line item details
    // if (formState.USER_SELECTED_PRODUCT_LINE_ITEMS.length > 0) {
    //     summary += `Line Items:\r\n`;
    //     formState.USER_SELECTED_PRODUCT_LINE_ITEMS.forEach((item, index) => {
    //         const foundProd = dataSource.products.filter((p) => p.uuid === item.association)
    //         const productDescription = foundProd ? foundProd[0].name : 'No Description';

    //         const productQuantity = item.PRODUCT_QUANTITY || 0;
    //         const productUnit = unitDisplayLabels[item.PRODUCT_UNIT] || item.PRODUCT_UNIT || 'unit';
    //         summary += `  ${index + 1}. ${productDescription} - ${productQuantity} ${productUnit}\r\n`;
    //     });
    // } else {
    //     summary += `No line items provided.\r\n`;
    // }

    return "Here is the order summary, please email me at the cc'd address if you have any questions!";
};

export {convertDayjsToStrings, convertStringsToDayjs, exportToExcel, exportToCSV, getNextPeriod, buildTimePeriods, buildTimeKey, parseTimeKey, buildWeekLabel, volumeFormatter, detailedVolumeFormatter, currencyFormatter, detailedCurrencyFormatter, getPeriodStart, findChangedIndex, nullSafeConcat, generateOrderSummary};
