import { Table, AutoComplete, Row, Input, Col, Spin, Button, Typography, Modal, Select, Tag, Checkbox, Form, Skeleton, Tooltip, Tabs, InputNumber } from 'antd';
import { Link, useNavigation, useLoaderData } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import { fetchThreadsData } from "../../endpoints/fetchThreadsData";
import { DeleteOutlined, SearchOutlined, LoadingOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { currencyFormatter, detailedCurrencyFormatter, volumeFormatter } from "../utils";
import Loading from "../Loading";
import { useSearchParams, useOutletContext } from 'react-router-dom';
import dayjs from "dayjs";

const { Text } = Typography;
const { TabPane } = Tabs;

const fobDisplayLabels = {
    destination: 'DAP (Delivery)',
    origin: 'FOB (Pickup)',
};

const unitDisplayLabels = {
    eaches: 'Units',
    cases: 'Cases',
    pallets: 'Pallets',
};

const Settings = (props) => {
    const { userData, setHasError } = props;
    const { accessToken } = userData;

    const [forReviewCount, setForReviewCount, products, setProducts, orderUnit, setOrderUnit, custConfigs, accountConfig, setAccountConfig] = useOutletContext();
    const [filterText, setFilterText] = useState('');
    const [activeTabKey, setActiveTabKey] = useState('1');
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [editingProduct, setEditingProduct] = useState(null);
    const [isAddingNew, setIsAddingNew] = useState(false);
    const [updatingProducts, setUpdatingProducts] = useState([]);
    const [customerConfigs, setCustomerConfigs] = useState(null);
    const [automationSettings, setAutomationSettings] = useState(null);
    const [editingCustomerConfig, setEditingCustomerConfig] = useState(null);
    const abortControllerRef = useRef(null);
    const searchInputRef = useRef(null);

    useEffect(() => {
        // handle initial config state
        if (customerConfigs === null) setCustomerConfigs(custConfigs)
        if (automationSettings === null) {
            setAutomationSettings(accountConfig) 
        }

    }, [custConfigs, accountConfig]);

    const handleCheckboxChange = async (e) => {
        const newConf = {
            ...automationSettings,
            [e.target.name]: e.target.checked,
        };

        if (e.target.type === 'checkbox') {
            setAutomationSettings(automationSettings => newConf);
        }
        
        const response = await fetchThreadsData(
            accessToken,
            { 'info_type': 'account_config_update', 'entity': newConf },
        )
    };

    const handleSave = async () => {
        setIsModalVisible(false);
        const copy = editingProduct;

        setUpdatingProducts([...updatingProducts, copy.uuid]);
        const uuid = copy.uuid;
        await fetchThreadsData(
            accessToken,
            { 'info_type': 'product_update', 'entity': copy },
        ).then(() => {
            setUpdatingProducts(updatingProducts.filter(p => p !== uuid));
        });
        setProducts(products.map((p) => p.uuid === uuid ? copy : p));
    };

    const handleDelete = async () => {
        setIsModalVisible(false);
        const copy = editingProduct;

        setUpdatingProducts([...updatingProducts, copy.uuid]);
        const uuid = copy.uuid;
        await fetchThreadsData(
            accessToken,
            { 'info_type': 'product_delete', 'entity': copy },
        ).then(() => {
            setUpdatingProducts(updatingProducts.filter(p => p !== uuid));
        });
        setProducts(products.filter((p) => p.uuid !== uuid));
    };


    const filteredCustomerConfigs = Object.values(customerConfigs ? customerConfigs : {}).filter(
        (config) =>
            config.name !== 'default' &&
            (config.name.toLowerCase().includes(filterText.toLowerCase()))
    );

    const handleCaseSizeChange = (e) => {
        setEditingProduct({ ...editingProduct, case_size: e.target.value });
    };

    const handlePalletSizeChange = (e) => {
        setEditingProduct({ ...editingProduct, pallet_size: e.target.value });
    };

    const handleAddNew = () => {
        const newProduct = {
            uuid: crypto.randomUUID(), name: '', keywords: '', web_store: false, isGhost: true,
            case_size: 1, pallet_size: 1, price: 0, archived: false, web_store_key: null,
        };
        setProducts([...products, newProduct]);
        setEditingProduct(newProduct);
        setIsModalVisible(true);
        setIsAddingNew(true);
    };

    const parseKeywords = (keywords) => {
        return keywords.split(/\*|\*/).reduce((acc, item, index) => {
            if (index % 2 === 0) {
                // Plain text between tags
                //acc.push(<span key={`text-${index}`}>{item}</span>);
            } else {
                const attrib = item.split(':')
                // Tag
                acc.push(<><Tag key={`tag-${index}`} color="blue">{attrib[0]}: {attrib[1]}</Tag></>);
            }
            return acc;
        }, []);
    };

    const handleEdit = (product) => {
        setEditingProduct(product);
        setIsModalVisible(true);
        setIsAddingNew(false);
    };

    const handleNameChange = (e) => {
        setEditingProduct({ ...editingProduct, name: e.target.value });
    };

    const handleKeywordsChange = (e) => {
        setEditingProduct({ ...editingProduct, keywords: e.target.value });
    };

    const filteredProducts = products.filter(
        (product) =>
            product.name.toLowerCase().includes(filterText.toLowerCase()) ||
            product.keywords.toLowerCase().includes(filterText.toLowerCase())
    );

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: 200,
        },
        {
            title: 'Is Active',
            dataIndex: 'archived',
            key: 'archived',
            render: (archived, record) => {
                return updatingProducts.includes(record.uuid) ? <Skeleton.Input style={{ width: 100, height: 25 }} active /> : (
                    archived ? <Tag color='yellow'>Inactive</Tag> : <Tag>Active</Tag>
                )
            },
        },
        {
            title: 'Unit of Measure',
            dataIndex: 'enforce_unit',
            key: 'enforce_unit',
            render: (enforce_unit, record) => (
                updatingProducts.includes(record.uuid) ? <Skeleton.Input style={{ width: 100, height: 25 }} active /> : unitDisplayLabels[enforce_unit]
            ),
        },
        {
            title: 'Case Size (Units)',
            dataIndex: 'case_size',
            key: 'case_size',
            render: (case_size, record) => (
                updatingProducts.includes(record.uuid) ? <Skeleton.Input style={{ width: 100, height: 25 }} active /> : case_size
            ),
        },
        {
            title: 'Pallet Size (Cases)',
            dataIndex: 'pallet_size',
            key: 'pallet_size',
            render: (pallet_size, record) => (
                updatingProducts.includes(record.uuid) ? <Skeleton.Input style={{ width: 100, height: 25 }} active /> : pallet_size
            ),
        },
        {
            title: `Price`,
            dataIndex: 'price',
            key: 'price',
            render: (price, record) => (
                updatingProducts.includes(record.uuid) ? <Skeleton.Input style={{ width: 100, height: 25 }} active /> : detailedCurrencyFormatter.format(price)
            ),
        },
        {
            title: 'Attributes',
            dataIndex: 'keywords',
            key: 'keywords',
            render: (keywords, record) => (
                updatingProducts.includes(record.uuid) ? 
                <div style={{ maxWidth: '500px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                    <Skeleton.Input style={{ width: 400, height: 25 }} active />
                </div> :
                <div style={{ maxWidth: '500px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                    {parseKeywords(keywords)}
                </div>
            ),
        },
        {
            title: 'Action',
            key: 'action',
            width: 100,
            render: (_, record) => (
                updatingProducts.includes(record.uuid) ? <Skeleton.Button shape="round" active style={{ width: 58, height: 32 }} /> :
                    <Button type="link" onClick={() => handleEdit(record)}>
                        Edit
                    </Button>
            ),
        },
    ];

    if (!orderUnit) return <Loading style={{paddingTop: 200}}/>;

    return (
        <div style={{ overflow: 'auto', height: 'calc(100vh - 58px)' }}>
            <Tabs defaultActiveKey="1" activeKey={activeTabKey} onChange={setActiveTabKey} style={{ padding: '0 15px' }}>
                <TabPane tab="Product Settings" key="1">
                    <Row>
                        <Col flex={'300px'}>
                            <Form.Item
                                style={{ minWidth: 100, maxWidth: 100, paddingLeft: 15 }}
                                label="Export Unit"
                            >
                                <Select
                                    value={orderUnit}
                                    options={[
                                        {
                                            'label': unitDisplayLabels['eaches'],
                                            'value': 'eaches'
                                        },
                                        {
                                            'label': unitDisplayLabels['cases'],
                                            'value': 'cases'
                                        },
                                        {
                                            'label': unitDisplayLabels['pallets'],
                                            'value': 'pallets'
                                        },
                                    ]}
                                    onChange={async (val) => {
                                        setOrderUnit(val);
                                        await fetchThreadsData(
                                            accessToken,
                                            { 'info_type': 'account_update', 'entity': { 'default_po_unit': val } },
                                        );
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col flex='auto' />
                    </Row>
                    <Row justify="end" align="middle" style={{ margin: '15px' }}>
                        <Col flex="auto">
                            <Input
                                placeholder="Filter products..."
                                value={filterText}
                                onChange={(e) => setFilterText(e.target.value)}
                                prefix={<SearchOutlined />}
                                allowClear
                                style={{ width: '300px' }}
                            />
                        </Col>
                        <Col>
                            <Button type="primary" onClick={handleAddNew} style={{ marginLeft: '10px' }}>
                                Add New
                            </Button>
                        </Col>
                    </Row>
                    <Table style={{ padding: 15 }} columns={columns} dataSource={filteredProducts} rowKey="uuid" />
                    {editingProduct &&
                        <Modal
                            title={isAddingNew ? 'Add New Product' : 'Edit Product'}
                            open={isModalVisible}
                            footer={
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Button danger onClick={handleDelete}>
                                        Delete
                                    </Button>
                                    <div>
                                        <Button type="primary" onClick={handleSave}>
                                            Save
                                        </Button>
                                    </div>
                                </div>
                            }
                            onCancel={() => {
                                setIsModalVisible(false);
                                setEditingProduct(null);
                                if (isAddingNew) {
                                    setProducts(products.filter(p => !p.isGhost));
                                }
                            }}
                        >
                            <Form layout="vertical">
                                <Form.Item label="Name">
                                    <Input placeholder="Product display name" value={editingProduct?.name} onChange={handleNameChange} />
                                </Form.Item>
                                <Form.Item label="Unit of Measure">
                                    <Select
                                        value={editingProduct?.enforce_unit}
                                        onChange={(value) => setEditingProduct({ ...editingProduct, enforce_unit: value })}
                                        options={Object.entries(unitDisplayLabels).map(([value, label]) => ({ label, value }))}
                                    />
                                </Form.Item>
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <Form.Item label="Case Size (Units)">
                                            <Input
                                                type="number"
                                                min={0}
                                                placeholder="Number of units in a case"
                                                value={editingProduct?.case_size}
                                                onChange={handleCaseSizeChange}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label="Pallet Size (Cases)">
                                            <Input
                                                type="number"
                                                min={0}
                                                placeholder="Number of cases in a pallet"
                                                value={editingProduct?.pallet_size}
                                                onChange={handlePalletSizeChange}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <Form.Item label={`Price (${unitDisplayLabels[orderUnit]})`}>
                                            <InputNumber
                                                type="number"
                                                min={0}
                                                placeholder="Product price"
                                                value={editingProduct?.price}
                                                onChange={(e) => setEditingProduct({ ...editingProduct, price: e })}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label="Is Active">
                                        <Select
                                            value={editingProduct?.archived ? 'inactive' : 'active'}
                                            onChange={(value) => setEditingProduct({ ...editingProduct, archived: value === 'inactive' })}
                                        >
                                            <Select.Option value="active">Active</Select.Option>
                                            <Select.Option value="inactive">Inactive</Select.Option>
                                        </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Form.Item label="Attributes (fuzzy AI match)">
                                    <Input.TextArea
                                        placeholder="Product description and keywords e.g. SKU, flavor, size, variant, product line"
                                        value={editingProduct.keywords}
                                        onChange={handleKeywordsChange}
                                        rows={4}
                                    />
                                </Form.Item>
                            </Form>
                        </Modal>
                    }
                </TabPane>
                <TabPane tab="Automation Settings" key="3">
                    {automationSettings && <Form layout="vertical">
                        <Row>
                            <Col flex='500px'>
                                <Text style={{ margin: '15px' }}>AI Auto-Fill</Text>
                                <Form layout="vertical" style={{ margin: '15px' }}>
                                    <Row>
                                        <Col span={24}>
                                            <Checkbox
                                                name="financialLineItems"
                                                value={automationSettings ? automationSettings.financialLineItems : null}
                                                defaultChecked={automationSettings ? automationSettings.financialLineItems : null}
                                                onChange={handleCheckboxChange}
                                            >
                                                Financial line items (tax, commissions, charges, etc.)
                                            </Checkbox>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                            <Col flex='auto' />
                        </Row>
                        {/* <Row>
                            <Col flex='800px'>
                                <Text style={{ margin: '15px' }}>Apply these checks in order approvals</Text>
                                <Form layout="vertical" style={{ margin: '15px' }}>
                                    <Row>
                                        <Col span={24}>
                                            <Form.Item>
                                                <Checkbox
                                                    name="leadTime"
                                                    checked={autoCreateConditions.leadTime}
                                                    onChange={handleCheckboxChange}
                                                >
                                                    Requested order due date needs at least a lead time of 14 days
                                                </Checkbox>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={24}>
                                            <Form.Item>
                                                <Checkbox
                                                    name="inactiveProduct"
                                                    checked={autoCreateConditions.inactiveProduct}
                                                    onChange={handleCheckboxChange}
                                                >
                                                    Can't place an order for an Inactive product
                                                </Checkbox>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={24}>
                                            <Form.Item>
                                                <Checkbox
                                                    name="listedPrice"
                                                    checked={autoCreateConditions.listedPrice}
                                                    onChange={handleCheckboxChange}
                                                >
                                                    Order product prices must match the price configured for that customer (or the default price if no customer price is configured)
                                                </Checkbox>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={24}>
                                            <Form.Item>
                                                <Checkbox
                                                    name="lineMoq"
                                                    checked={autoCreateConditions.lineMoq}
                                                    onChange={handleCheckboxChange}
                                                >
                                                    Each product line item quantity must be a multiple of{' '}
                                                    <InputNumber
                                                        min={1}
                                                        value={autoCreateConditions.lineMoqValue}
                                                        onChange={(value) => setAutoCreateConditions({ ...autoCreateConditions, lineMoqValue: value })}
                                                        style={{ width: '100px', marginLeft: '5px', marginRight: '5px' }}
                                                    />
                                                    <Select
                                                        value={autoCreateConditions.lineMoqUnit}
                                                        options={[
                                                            {
                                                                'label': unitDisplayLabels['eaches'],
                                                                'value': 'eaches'
                                                            },
                                                            {
                                                                'label': unitDisplayLabels['cases'],
                                                                'value': 'cases'
                                                            },
                                                            {
                                                                'label': unitDisplayLabels['pallets'],
                                                                'value': 'pallets'
                                                            },
                                                        ]}
                                                        onChange={(value) => setAutoCreateConditions({ ...autoCreateConditions, lineMoqUnit: value })}
                                                        style={{ width: '100px' }}
                                                    />
                                                </Checkbox>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={24}>
                                            <Form.Item>
                                                <Checkbox
                                                    name="totalMoq"
                                                    checked={autoCreateConditions.totalMoq}
                                                    onChange={handleCheckboxChange}
                                                >
                                                    An order total quantity must be a multiple of{' '}
                                                    <InputNumber
                                                        min={1}
                                                        value={autoCreateConditions.totalMoqValue}
                                                        onChange={(value) => setAutoCreateConditions({ ...autoCreateConditions, totalMoqValue: value })}
                                                        style={{ width: '100px', marginLeft: '5px', marginRight: '5px' }}
                                                    />
                                                    <Select
                                                        value={autoCreateConditions.totalMoqUnit}
                                                        onChange={(value) => setAutoCreateConditions({ ...autoCreateConditions, totalMoqUnit: value })}
                                                        style={{ width: '100px' }}
                                                    >
                                                        <Select.Option value="cases">cases</Select.Option>
                                                        <Select.Option value="pallets">pallets</Select.Option>
                                                    </Select>
                                                </Checkbox>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                            <Col flex='auto' />
                        </Row> */}
                    </Form>}
                </TabPane>
            </Tabs>
        </div>
    );
};

export { Settings, fobDisplayLabels, unitDisplayLabels};
